<template>
    <div>
        <Pop ref="pop" :clickClose="clickClose" position="Bottom">
            <template v-slot:popContent>
                <div class="selectContent">
                    <div class="timeHead">
                        <span class="timeLeft">
                            <slot name="selectLeft"></slot>
                        </span>
                        {{title}}
                        <span class="timeOk" @click="overChoose">{{okTxt||'完成'}}</span>
                    </div>
                    <div class="selectRow">
                        <!--<mt-picker :slots="init()" :valueKey="val" @change="onchangePicker"></mt-picker>-->
                        <ScrollPicker :pickerList="init()" :val="val" @change-picker="onchangePicker"></ScrollPicker>
                    </div>

                    <div v-if="!selectList.length" class="noList">
                        暂无数据
                    </div>
                </div>
            </template>
        </Pop>
    </div>
</template>

<script>
    export default {
        name: "Select",
        props: [
            'selectList',//选择器数据源
            'val',//显示的字段
            'title',//标题
            'okTxt',//确定文字
            'clickClose',//是否点击空白处关闭
        ],
        data() {
            return {
                value: {}
            }
        },
        created() {
        },
        methods: {
            init() {
                return this.selectList
            },
            openPop() {
                this.$refs.pop.changePop();
            },
            overChoose() {
                this.$emit('on-ok', this.value);
                this.openPop();
            },
            onchangePicker(e) {
                this.value = e;
            }
        }
    }
</script>

<style lang="scss">
    

    .timeHead {
        text-align: center;
        font-weight: 500;
        font-size: .15rem;
        padding: .2rem;
        position: relative;
    }

    .timeOk,.timeLeft {
        position: absolute;
        top: .1rem;
        font-size: .12rem;
        padding: .12rem .12rem 0 .12rem;
    }
    .timeLeft{
        left: .3rem;
    }
    .timeOk{
        color:  var(--themeColor);
        right: .3rem;
    }
    .selectRow{
        float: left;
        width: 100%;
        position: relative;
        background: #fff;
    }

    .selectList {

    }

    .selectList li {
        height: .5rem;
        line-height: .5rem;
        text-align: center;
        font-size: .15rem;
        padding: 0 .8rem 0 .25rem;
        position: relative;
        color: #333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .selectBg {
        /*background: rgba(255, 135, 16, 0.05);*/
    }

    .selectOn {
        color:  var(--themeColor);
        background: rgba(255, 135, 16, 0.2);
    }

    .noList {
        padding: .5rem 0;
        color: #666;
        position: absolute;
        text-align: center;
        width: 100%;
        font-size: .18rem;
    }

    .selectIcon {
        width: .25rem !important;
        height: .25rem !important;
        position: absolute;
        right: 1rem;
        top: .2rem;
    }
</style>